import React, { useEffect, Fragment, useState } from "react";
import _ from "lodash";
import { Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import PostCategoryHOC from "../actions/postCategory";

function Categories({
  selectedVideo,
  postCategory,
  categories,
  getPostCategory,
  createPostCategory,
  deletePostCategory,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getPostCategory(selectedVideo.id);
  }, []);

  const getFilteredCategories = () => {
    return categories.reduce((acc, category) => {
      const matchesCategory = category.name.includes(searchTerm);

      const matchedChildren = matchesCategory
        ? category.children || []
        : category.children?.filter((child) =>
            child.name.includes(searchTerm)
          ) || [];

      if (matchesCategory || matchedChildren.length > 0) {
        acc.push({
          ...category,
          children: matchedChildren,
        });
      }

      return acc;
    }, []);
  };

  const filteredCategories = getFilteredCategories();

  const isParentChecked = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    if (!category) return false;

    return category.children.every(
      (child) =>
        _.findIndex(postCategory, { mywheels_category_id: child.id }) > -1
    );
  };

  const toggleParentCheckbox = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    const isChecked = isParentChecked(categoryId);

    if (isChecked) {
      category.children.forEach((child) => {
        const childIndex = _.findIndex(postCategory, {
          mywheels_category_id: child.id,
        });
        if (childIndex > -1) {
          deletePostCategory(
            postCategory[childIndex].id,
            postCategory[childIndex].post_id
          );
        }
      });
    } else {
      category.children.forEach((child) => {
        createPostCategory({
          post_id: selectedVideo.id,
          mywheels_category_id: child.id,
        });
      });
    }
  };

  return (
    <Card>
      <CardBody>
        <Label className="mb-2">Post Categories</Label>
        <Input
          type="text"
          placeholder="Search categories"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="searchBar"
        />
        <hr />
        <Form>
          {filteredCategories.map((categoryChild) => (
            <div key={`category_${categoryChild.id}`}>
              <FormGroup>
                <FormGroup>
                  <Input
                    type="checkbox"
                    color="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "2px",
                    }}
                    checked={isParentChecked(categoryChild.id)}
                    onChange={() => toggleParentCheckbox(categoryChild.id)}
                  />
                  <strong style={{ paddingLeft: "22px" }}>
                    {categoryChild.name}
                  </strong>
                </FormGroup>

                {categoryChild.children.map((subChild) => (
                  <Fragment key={`category_child_${subChild.id}`}>
                    <FormGroup style={{ marginLeft: "2.6rem" }}>
                      <Input
                        type="checkbox"
                        color="primary"
                        checked={
                          _.findIndex(postCategory, {
                            mywheels_category_id: subChild.id,
                          }) > -1
                        }
                        onChange={() => {
                          let tempIndex = _.findIndex(postCategory, (child) => {
                            return child.mywheels_category_id === subChild.id;
                          });

                          tempIndex < 0
                            ? createPostCategory({
                                post_id: selectedVideo.id,
                                mywheels_category_id: subChild.id,
                              })
                            : postCategory[tempIndex]?.id &&
                              deletePostCategory(
                                postCategory[tempIndex].id,
                                postCategory[tempIndex].post_id
                              );  
                        }}
                      />
                      <span style={{ paddingLeft: "3px" }}>
                        {subChild.name}
                      </span>
                    </FormGroup>
                  </Fragment>
                ))}
              </FormGroup>
            </div>
          ))}
        </Form>
      </CardBody>
    </Card>
  );
}

export default PostCategoryHOC(Categories);
