import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import Pagination from "components/Pagination";
import SearchForm from "components/SearchForm";
import MyWheelsTable from "components/Table";
import _ from "lodash";
import Moment from "moment";
import { useEffect, useState } from "react";
import { queryHandler } from "utils/queryHandler";
import { requestError } from "utils/requestHandler";
import HOC from "../actions";

function FurtherReadingPost({
  pageIndex,
  searchParams,
  posts,
  flattenCategories,
  getPost,
  onSubmit,
  postCounts,
  onChangePostHOC,
}) {
  const [selectedPost, setSelectedPost] = useState([]);

  useEffect(() => {
    let tempQeuery = queryHandler(searchParams);

    let tempIndex = _.findIndex(
      searchParams,
      (val) => val.value === "slug_category_id"
    );
    if (tempIndex > -1) {
      let tempSearchParams = _.cloneDeep(searchParams);
      tempSearchParams[tempIndex]["options"] = flattenCategories;

      onChangePostHOC("searchParams", tempSearchParams);
    }

    getPost(tempQeuery);
  }, [pageIndex]);

  const handleSelectPost = (val) => {
    if (selectedPost.length < 2) {
      const index = selectedPost.findIndex((post) => post.id === val.id);
      if (index > -1) {
        setSelectedPost((prevSelected) =>
          prevSelected.filter((post) => post.id !== val.id)
        );
      } else {
        setSelectedPost((prevSelected) => [...prevSelected, val]);
      }
    } else {
      requestError("Please only select 2 posts");
    }
  };

  return (
    <>
      <CustomModalBody>
        <SearchForm
          searchParams={searchParams}
          onChangeHOCState={onChangePostHOC}
          getListAPI={getPost}
        />
        <MyWheelsTable
          data={posts}
          showPagination={false}
          columnsContent={[
            {
              Header: "Title",
              centerColumn: true,
              Cell: (val) => <>{val.title || "N/A"}</>,
            },
            {
              Header: "Pin",
              centerColumn: true,
              Cell: (val) => val.position || "-",
            },
            {
              Header: "Category",
              centerColumn: true,
              Cell: (val) =>
                _.find(flattenCategories, (temp) => {
                  return temp.id === val.slug_category_id;
                })?.name ?? "-",
            },
            {
              Header: "Tags",
              centerColumn: true,
              Cell: (val) => (
                <>
                  {val.tags.map((tagChild) => (
                    <p className="mb-0">{`- ${tagChild.name}`}</p>
                  ))}
                  {val.tags.length < 1 && <span>-</span>}
                </>
              ),
            },
            {
              Header: "Status",
              centerColumn: true,
              Cell: (val) => (
                <>
                  <span
                    className={`badge ${
                      val.status === "active"
                        ? "badge-success"
                        : "badge-secondary"
                    }`}
                  >
                    {val.status}
                  </span>
                </>
              ),
            },
            {
              Header: "Schedule Date",
              centerColumn: true,
              Cell: (val) => (
                <>{Moment(val.start_at).format("DD-MM-YYYY h:mm a")}</>
              ),
            },
            {
              Header: "Published Date",
              centerColumn: true,
              Cell: (val) => (
                <>
                  {Moment(val.start_at).isBefore(Moment())
                    ? Moment(val.start_at).format("DD-MM-YYYY h:mm a")
                    : "-"}
                </>
              ),
            },
          ]}
          actionsContent={[
            {
              content: () => "Select",
              actionID: "SelectPost",
              colorFunc: (val) =>
                selectedPost.some((post) => post.id === val.id)
                  ? "primary"
                  : "secondary",
              tooltipContent: "Select",
              onClick: handleSelectPost,
            },
          ]}
        />
        <Pagination
          pageIndex={pageIndex}
          totalCount={postCounts}
          onChangePage={(val) => onChangePostHOC("pageIndex", val)}
        />
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            content: "Submit",
            disabled: selectedPost.length === 0,
            color: "primary",
            onClick: () => {
              onSubmit(selectedPost);
            },
          },
        ]}
      />
    </>
  );
}

export default HOC(FurtherReadingPost);
