import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { Get, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";
import { queryHandler } from "utils/queryHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      posts: [],
      postCounts: 0,
      pageIndex: 1,
      selectedPost: null,
      showEditPostModal: false,
      showCreatePostModal: false,
      selectedRestorePost: null,
      selectedDeletePost: null,
      searchParams: [],
    };

    onChangePostHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getPost = (val) => {
      Get(
        `/posts?${
          !val
            ? "&status=deleted"
            : `${val}&offset=${
                (this.state.pageIndex - 1) * 10
              }&limit=10&status=deleted`
        }`,
        this.getPostSuccess,
        this.getPostError,
        this.load
      );
    };
    getPostSuccess = (payload) => {
      this.setState({
        posts: payload.data.rows,
        postCounts: payload.data.count,
      });
    };
    getPostError = (error) => requestError(error);

    updatePost = (dataToSubmit) => {
      Put(
        `/posts/${dataToSubmit.id}`,
        {
          ...dataToSubmit,
          name: JSON.stringify(dataToSubmit.name),
          status: "draft",
        },
        this.updatePostSuccess,
        this.updatePostError,
        this.load
      );
    };
    updatePostSuccess = () => {
      requestSuccess("Record has been updated successfully.");
      this.setState({ selectedRestorePost: null });
      let tempQeuery = queryHandler(this.state.searchParams);

      this.getPost(tempQeuery);
    };
    updatePostError = (error) => requestError(error);

    deletePost = (id) => {
      Delete(
        `/posts/${id}`,
        this.deletePostSuccess,
        this.deletePostError,
        this.load
      );
    };
    deletePostSuccess = () => {
      requestSuccess("Post has been deleted successfully.");
      let tempQeuery = queryHandler(this.state.searchParams);

      this.getPost(tempQeuery);
      this.setState({
        selectedDeletePost: null,
      });
    };
    deletePostError = (error) => requestError(error.message);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          deletePost={this.deletePost}
          selectedDeletePost={this.state.selectedDeletePost}
          selectedRestorePost={this.state.selectedRestorePost}
          postCounts={this.state.postCounts}
          pageIndex={this.state.pageIndex}
          posts={this.state.posts}
          searchParams={this.state.searchParams}
          onLoadPost={this.state.loading}
          getPost={this.getPost}
          updatePost={this.updatePost}
          onChangePostHOC={this.onChangePostHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
