import React, { useState, Fragment } from "react";
import _ from "lodash";
import { Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";

function Categories({ categories, postCategories, setPostcategories }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleCategoryChange = (item, isParent = false) => {
    let temp = _.cloneDeep(postCategories);

    if (isParent) {
      const parentIndex = temp.findIndex(
        (child) => child.mywheels_category_id === item.id
      );

      if (parentIndex > -1) {
        temp = temp.filter(
          (child) =>
            child.mywheels_category_id !== item.id &&
            !item.children.some(
              (subChild) => subChild.id === child.mywheels_category_id
            )
        );
      } else {
        temp.push({ mywheels_category_id: item.id });
        item.children.forEach((subChild) => {
          if (
            !temp.some((child) => child.mywheels_category_id === subChild.id)
          ) {
            temp.push({ mywheels_category_id: subChild.id });
          }
        });
      }
    } else {
      const index = temp.findIndex(
        (child) => child.mywheels_category_id === item.id
      );

      if (index > -1) {
        temp.splice(index, 1);
      } else {
        temp.push({ mywheels_category_id: item.id });
      }
    }
    setPostcategories(temp);
  };

  const getFilteredCategories = () => {
    return categories.reduce((acc, category) => {
      const matchesCategory = category.name.includes(searchTerm);

      const matchedChildren = matchesCategory
        ? category.children || []
        : category.children?.filter((child) =>
            child.name.includes(searchTerm)
          ) || [];

      if (matchesCategory || matchedChildren.length > 0) {
        acc.push({
          ...category,
          children: matchedChildren,
        });
      }

      return acc;
    }, []);
  };

  const filteredCategories = getFilteredCategories();

  return (
    <Card>
      <CardBody>
        <Label className="mb-2">Sub Categories</Label>
        <Input
          type="text"
          placeholder="Search categories"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="searchBar"
        />
        <hr />
        <Form>
          {filteredCategories.map((category) => (
            <div key={`category_${category.id}`}>
              <Label>
                <Input
                  type="checkbox"
                  color="primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "2px",
                  }}
                  checked={
                    _.findIndex(postCategories, {
                      mywheels_category_id: category.id,
                    }) > -1 &&
                    category.children.every((subChild) =>
                      postCategories.some(
                        (postCat) =>
                          postCat.mywheels_category_id === subChild.id
                      )
                    )
                  }
                  onChange={() => handleCategoryChange(category, true)}
                />
                <strong style={{ paddingLeft: "22px" }}>{category.name}</strong>
              </Label>
              <FormGroup>
                {category.children && (
                  <div style={{ marginLeft: "2.6rem" }}>
                    {category.children.map((subChild) => (
                      <Fragment key={`category_child_${subChild.id}`}>
                        <FormGroup>
                          <Input
                            type="checkbox"
                            color="primary"
                            checked={
                              _.findIndex(postCategories, {
                                mywheels_category_id: subChild.id,
                              }) > -1
                            }
                            onChange={() => handleCategoryChange(subChild)}
                          />
                          <span style={{ paddingLeft: "3px" }}>
                            {subChild.name}
                          </span>
                        </FormGroup>
                      </Fragment>
                    ))}
                  </div>
                )}
              </FormGroup>
            </div>
          ))}
        </Form>
      </CardBody>
    </Card>
  );
}

export default Categories;
