import CustomModalBody from "components/Modal/body";
import CustomModalFooter from "components/Modal/footer";
import _ from "lodash";
import Moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineCloseCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import ImageField from "components/Input/File";
import RTE from "components/Input/RTE";
import CustomModal from "components/Modal";
import CarModelBrand from "components/Modal/CarBrand";
import CarModelModal from "components/Modal/CarModel";
import { SamplePostData } from "../assets";
import BulkImageField from "../ImageField";
import FurtherReadingModal from "../Modal/FurtherReadingModal";
import VideoModal from "../Modal/VideoModal";
import CategoryContent from "./Category";

import "react-datepicker/dist/react-datepicker.css";

export default function PostForm({
  videographers,
  reporters,
  userID,
  flattenCategories,
  tags,
  categories,
  onSubmit,
  createTag,
  addNewReporterOrVideographer,
  createTempPostForPreview,
}) {
  const typeaheadRef = useRef();
  const videographerTypeaheadRef = useRef();
  const reporterTypeaheadRef = useRef();

  const [reporterSearch, setReporterSearch] = useState("");
  const [videographerSearch, setVideographerSearch] = useState("");

  const [showChoosePost, setChoosePost] = useState(false);
  const [tempSelectedPost, setTempSelectedPost] = useState([]);
  const [postForm, setPostForm] = useState({
    ...SamplePostData,
    created_by: userID,
  });

  const [typeaheadSearch, setTypeaheadSearch] = useState("");
  const [allowToCreateTag, setAllowCreate] = useState(false);
  const [showAddModel, setAddModel] = useState(false);
  const [postCarModels, setCarModel] = useState([]);

  const [showAddBrand, setAddBrand] = useState(false);
  const [postCarBrands, setCarBrands] = useState([]);

  const [postVideos, setPostVideo] = useState([]);
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [postCategories, setPostcategories] = useState([]);
  const [selectedPostVideoIndex, setSelectedPostVideoIndex] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [videoGrapher, setVideoGrapher] = useState("");
  const [reporter, setReporter] = useState("");

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [typeaheadSearch]);

  const onChangeField = (key, val) => {
    setPostForm({
      ...postForm,
      [key]: val,
    });
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && typeaheadSearch.length > 0) {
      const matchedOptions = tags.filter((option) => {
        return (
          option.label.toLowerCase().includes(typeaheadSearch.toLowerCase()) &&
          !postForm?.mywheels_tags.some(
            (eachTag) => eachTag.label === option.label
          )
        );
      });
      const selectedTag = matchedOptions[0];
      if (selectedTag) {
        const newList = [...postForm.mywheels_tags];
        newList.push(selectedTag);
        setPostForm({
          ...postForm,
          mywheels_tags: newList,
        });
        typeaheadRef.current.clear();
      }
    }
  };

  return (
    <>
      <CustomModalBody fullScreen={true}>
        <Form>
          <Row>
            <Col md={12} lg={6} xl={8}>
              <FormGroup>
                <Label>
                  Title
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Add Title"
                  value={postForm.title}
                  onChange={(e) => onChangeField("title", e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Introduction
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Add Introduction"
                  value={postForm.introduction}
                  onChange={(e) =>
                    onChangeField("introduction", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <RTE
                  data={postForm.content}
                  height={"500px"}
                  formLabel={"Post Content"}
                  storagePath="posts"
                  disabled={false}
                  onChange={(event, editor) =>
                    onChangeField("content", editor.getData() || "")
                  }
                />
              </FormGroup>
              <FormGroup>
                <ImageField
                  storagePath="posts"
                  label={"Cover Image with Title"}
                  fileValue={postForm.cover_img_with_title_url}
                  onRemoveFile={() =>
                    onChangeField("cover_img_with_title_url", "")
                  }
                  onSelectImage={(val) => {
                    onChangeField("cover_img_with_title_url", val);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Cover Image Caption</Label>
                <Input
                  placeholder="Cover Image Caption"
                  value={postForm.cover_img_caption}
                  onChange={(e) =>
                    onChangeField("cover_img_caption", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <ImageField
                  storagePath="posts"
                  label={"Cover Image without Title"}
                  fileValue={postForm.cover_img_without_title_url}
                  onRemoveFile={() =>
                    onChangeField("cover_img_without_title_url", "")
                  }
                  onSelectImage={(val) =>
                    onChangeField("cover_img_without_title_url", val)
                  }
                />
              </FormGroup>
              {/* <FormGroup>
								<ImageField
									label={"Article Feature Image"}
									fileValue={postForm.article_feature_img_url}
									onRemoveFile={() =>
										onChangeField("article_feature_img_url", "")
									}
									onSelectImage={(val) =>
										onChangeField("article_feature_img_url", val)
									}
								/>
							</FormGroup> */}

              <FormGroup>
                <Label>
                  Further Reading Posts
                  <AiFillEdit
                    style={{ cursor: "pointer" }}
                    className="text-primary ml-2"
                    onClick={() => setChoosePost(true)}
                  />
                </Label>

                <Input
                  value={tempSelectedPost[0]?.title ?? "-"}
                  disabled={true}
                  readOnly
                />
                <br />
                <Input
                  value={tempSelectedPost[1]?.title ?? "-"}
                  disabled={true}
                  readOnly
                />
              </FormGroup>
              <Card className="mb-3">
                <CardHeader>
                  <div className="d-flex align-items-end w-100">
                    <span>Video</span>
                    <div className="ml-auto">
                      <Button
                        size={"sm"}
                        disabled={postVideos.length > 4}
                        color={"primary"}
                        onClick={() => setShowAddVideo(true)}
                      >
                        <AiOutlinePlus style={{ width: 12, height: 12 }} />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {postVideos.length < 1 && (
                    <div className="text-center">No videos is selected</div>
                  )}
                  {postVideos.map((videoChild, videoIndex) => (
                    <div className="card mb-2">
                      <div className="p-2 d-flex">
                        <span style={{ width: "max-content" }}>
                          {videoChild.url}
                        </span>
                        <AiFillEdit
                          style={{ cursor: "pointer" }}
                          className="text-primary mr-2 ml-auto"
                          onClick={() => {
                            setSelectedPostVideoIndex(videoIndex);
                          }}
                        />
                        <AiFillDelete
                          style={{ cursor: "pointer" }}
                          className="text-danger"
                          onClick={() => {
                            let temp = _.cloneDeep(postVideos);
                            temp.splice(videoIndex, 1);

                            setPostVideo(temp);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>

              <Card className="mb-3">
                <CardHeader>
                  <div className="d-flex align-items-end w-100">
                    <span>Car Brands</span>
                    <div className="ml-auto">
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => setAddBrand(true)}
                      >
                        <AiOutlinePlus style={{ width: 12, height: 12 }} />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {postCarBrands.length < 1 && (
                    <div className="text-center">No brands is selected</div>
                  )}
                  {postCarBrands.map((brandChild, brandIndex) => (
                    <Fragment key={`tag_${brandChild.id}`}>
                      <span
                        className="badge badge-white p-2"
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: 15,
                        }}
                      >
                        {brandChild.name}
                        <AiOutlineCloseCircle
                          className={"bg-white ml-1"}
                          style={{
                            cursor: "pointer",
                            width: 13,
                            height: 13,
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            let temp = _.cloneDeep(postCarBrands);
                            temp.splice(brandIndex, 1);

                            setCarModel(temp);
                          }}
                        />
                      </span>
                    </Fragment>
                  ))}
                </CardBody>
              </Card>

              <Card className="mb-3">
                <CardHeader>
                  <div className="d-flex align-items-end w-100">
                    <span>Car Models</span>
                    <div className="ml-auto">
                      <Button
                        size={"sm"}
                        color={"primary"}
                        onClick={() => setAddModel(true)}
                      >
                        <AiOutlinePlus style={{ width: 12, height: 12 }} />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {postCarModels.length < 1 && (
                    <div className="text-center">No models is selected</div>
                  )}
                  {postCarModels.map((modelChild, modelIndex) => (
                    <Fragment key={`tag_${modelChild.id}`}>
                      <span
                        className="badge badge-white p-2"
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: 15,
                        }}
                      >
                        {modelChild.title}
                        <AiOutlineCloseCircle
                          className={"bg-white ml-1"}
                          style={{
                            cursor: "pointer",
                            width: 13,
                            height: 13,
                            borderRadius: "50%",
                          }}
                          onClick={() => {
                            let temp = _.cloneDeep(postCarModels);
                            temp.splice(modelIndex, 1);

                            setCarModel(temp);
                          }}
                        />
                      </span>
                    </Fragment>
                  ))}
                </CardBody>
              </Card>
              <BulkImageField
                imageData={imageData}
                onRemove={(url, index) => {
                  let temp = _.cloneDeep(imageData);
                  temp.splice(index, 1);

                  setImageData(temp);
                }}
                onAddFile={(val) => {
                  let temp = _.cloneDeep(imageData);
                  if (Array.isArray(val)) {
                    val.map((eachValue) => {
                      temp.push({
                        url: eachValue,
                      });
                    });
                  } else {
                    temp.push({
                      url: val,
                    });
                  }
                  setImageData(temp);
                }}
              />
            </Col>
            <Col md={12} lg={6} xl={4} className="mywheels-post-form">
              <Card className="mb-3">
                <CardHeader>Article Details</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label>Schedule Date Time</Label>
                    <DatePicker
                      selected={
                        postForm.start_at ? new Date(postForm.start_at) : null
                      }
                      showYearDropdown
                      className="form-control"
                      minDate={Moment().toDate()}
                      popperPlacement="right-start"
                      onChange={(val) => onChangeField("start_at", val)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Main Category
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type={"select"}
                      value={postForm.slug_category_id}
                      onChange={(e) =>
                        onChangeField("slug_category_id", +e.target.value)
                      }
                    >
                      <option value=""></option>
                      {flattenCategories.map((categoryChild) => (
                        <option
                          key={`category_${categoryChild.id}`}
                          value={categoryChild.id}
                        >
                          {categoryChild.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <CategoryContent
                      categories={categories}
                      postCategories={postCategories}
                      setPostcategories={setPostcategories}
                    />
                  </FormGroup>
                  <Form>
                    <FormGroup check>
                      <Input
                        name="dont-pin"
                        checked={!postForm.position && !postForm.is_pin}
                        type="radio"
                        onClick={() => {
                          let temp = _.cloneDeep(postForm);
                          temp.is_pin = false;
                          delete temp.position;

                          setPostForm(temp);
                        }}
                      />{" "}
                      <Label check>Don't Pin</Label>
                    </FormGroup>
                    {[1, 2, 3, 4].map((index) => (
                      <>
                        <FormGroup check>
                          <Input
                            name={`radio${index}`}
                            type="radio"
                            checked={
                              postForm.position === index && postForm.is_pin
                            }
                            onClick={() => {
                              let temp = _.cloneDeep(postForm);
                              temp.is_pin = true;
                              temp.position = index;

                              setPostForm(temp);
                            }}
                          />{" "}
                          <Label check>Pin at Slider No. {` ${index}`}</Label>
                        </FormGroup>
                      </>
                    ))}
                  </Form>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardHeader>Tags</CardHeader>
                <CardBody>
                  {postForm.mywheels_tags.length < 1 && (
                    <div className="text-center">No tags is selected</div>
                  )}
                  <div className="d-flex flex-wrap mb-3" style={{ gap: 5 }}>
                    {postForm.mywheels_tags.length > 0 &&
                      postForm.mywheels_tags.map((tagChild) => (
                        <Fragment key={`tag_${tagChild.id}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {tagChild.name}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                let temp = _.cloneDeep(postForm.mywheels_tags);
                                _.remove(temp, (val) => val.id === tagChild.id);

                                onChangeField("mywheels_tags", temp);
                              }}
                            />
                          </span>
                        </Fragment>
                      ))}
                  </div>
                  <div className="d-flex w-100 alignt-items-center flex-wrap">
                    <Typeahead
                      ref={typeaheadRef}
                      id="basic-example"
                      onInputChange={(e) => {
                        const matchedOptions = tags.filter((option) =>
                          option.label.toLowerCase().includes(e.toLowerCase())
                        );

                        setAllowCreate(matchedOptions.length < 1);
                        setTypeaheadSearch(e);
                      }}
                      onChange={(val) => {
                        onChangeField("mywheels_tags", [
                          ...postForm.mywheels_tags,
                          ...val,
                        ]);
                      }}
                      options={[
                        ..._.filter(tags, (tagChild) => {
                          let tempIndex =
                            _.findIndex(postForm.mywheels_tags, {
                              id: tagChild.id,
                            }) < 0;

                          return tempIndex;
                        }),
                      ]}
                      placeholder="Choose a tag"
                      selected={[]}
                    />
                    <Button
                      size={"sm"}
                      className="ml-auto"
                      disabled={!typeaheadSearch || !allowToCreateTag}
                      onClick={() => {
                        createTag(
                          {
                            name: typeaheadSearch,
                          },
                          (val) => {
                            typeaheadRef.current.clear();
                            onChangeField("mywheels_tags", [
                              ...postForm.mywheels_tags,
                              val,
                            ]);
                          }
                        );
                      }}
                    >
                      Create
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardBody>
                  <FormGroup>
                    <Label>Videographer</Label>
                    {videoGrapher.length < 0 && (
                      <div className="text-center">
                        No videographer is selected
                      </div>
                    )}
                    <div className="flex w-full mb-3">
                      {videoGrapher.length > 0 && (
                        <Fragment key={`tag_${videoGrapher}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {videoGrapher}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setVideoGrapher("");
                              }}
                            />
                          </span>
                        </Fragment>
                      )}
                    </div>
                    <div className="d-flex w-100 alignt-items-center flex-wrap">
                      <Typeahead
                        ref={videographerTypeaheadRef}
                        labelKey="name"
                        onInputChange={(e) => {
                          setVideographerSearch(e);
                        }}
                        onChange={(val) => {
                          setVideoGrapher(val);
                          const selectedVideoGrapher = videographers.find(
                            (eachVideographer) => {
                              return eachVideographer.name == val;
                            }
                          );
                          if (selectedVideoGrapher)
                            postForm.photographer_id = selectedVideoGrapher.id;
                          else postForm.photographer_name = null;
                        }}
                        options={videographers.map(
                          (eachVideographer) => eachVideographer.name
                        )}
                        placeholder="Choose a videographer..."
                        selected={videoGrapher}
                      />
                      <Button
                        size={"sm"}
                        disabled={!videographerSearch}
                        className="ml-auto"
                        onClick={async () => {
                          videographerTypeaheadRef.current.clear();
                          if (videographerSearch) {
                            const videographerData =
                              await addNewReporterOrVideographer(
                                { name: videographerSearch },
                                "videographer"
                              );
                            postForm["photographer_id"] = videographerData.id;
                            postForm["photographer_name"] =
                              videographerData.name;
                            setVideoGrapher([videographerData.name]);
                            setVideographerSearch("");
                          }
                        }}
                      >
                        Create
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Reporter</Label>
                    {reporter.length < 0 && (
                      <div className="text-center">No reporter is selected</div>
                    )}
                    <div className="flex w-full mb-3">
                      {reporter.length > 0 && (
                        <Fragment key={`tag_${reporter}`}>
                          <span
                            className="badge badge-white p-2"
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: 15,
                            }}
                          >
                            {`# `} {reporter}
                            <AiOutlineCloseCircle
                              className={"bg-white ml-1"}
                              style={{
                                cursor: "pointer",
                                width: 13,
                                height: 13,
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setReporter("");
                              }}
                            />
                          </span>
                        </Fragment>
                      )}
                    </div>
                    <div className="d-flex w-100 alignt-items-center flex-wrap">
                      <Typeahead
                        ref={reporterTypeaheadRef}
                        labelKey="name"
                        onInputChange={(e) => {
                          setReporterSearch(e);
                        }}
                        onChange={(val) => {
                          setReporter(val);
                          const selectedReporter = reporters.find(
                            (eachReporter) => {
                              return eachReporter.name == val;
                            }
                          );
                          if (selectedReporter)
                            postForm.author_id = selectedReporter.id;
                          else postForm.author_id = null;
                        }}
                        options={reporters.map(
                          (eachReporter) => eachReporter.name
                        )}
                        placeholder="Choose a reporter..."
                        selected={reporter}
                      />
                      <Button
                        size={"sm"}
                        disabled={!reporterSearch}
                        className="ml-auto"
                        onClick={async () => {
                          reporterTypeaheadRef.current.clear();
                          if (reporterSearch) {
                            const reporterData =
                              await addNewReporterOrVideographer(
                                { name: reporterSearch },
                                "reporter"
                              );
                            postForm["author_id"] = reporterData.id;
                            postForm["author_name"] = reporterData.name;
                            setReporter([reporterData.name]);
                            setReporterSearch("");
                          }
                        }}
                      >
                        Create
                      </Button>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </CustomModalBody>
      <CustomModalFooter
        rightButton={[
          {
            color: "primary",
            content: "Save As Draft",
            disabled:
              !postForm.title ||
              !postForm.slug_category_id ||
              !postForm.introduction,
            onClick: async () => {
              let temp = _.cloneDeep(postForm);
              temp.status = "draft";
              temp.published_at = null;
              temp.start_at = new Date();

              onSubmit(
                temp,
                postVideos,
                postCategories,
                imageData,
                postCarModels,
                postCarBrands
              );
            },
          },
          {
            color: "primary",
            content: "Save As Scheduled",
            disabled:
              !postForm.title ||
              !postForm.slug_category_id ||
              !postForm.introduction,
            onClick: async () => {
              onSubmit(
                postForm,
                postVideos,
                postCategories,
                imageData,
                postCarModels,
                postCarBrands
              );
            },
          },
          {
            color: "success",
            content: "Live Now",
            disabled:
              !postForm.title ||
              !postForm.slug_category_id ||
              !postForm.introduction ||
              postForm.status === "live",
            onClick: async () => {
              let temp = _.cloneDeep(postForm);
              temp.status = "live";
              temp.start_at = new Date();
              temp.published_at = new Date();

              onSubmit(
                temp,
                postVideos,
                postCategories,
                imageData,
                postCarModels,
                postCarBrands
              );
            },
          },
          {
            color: "warning",
            disabled:
              !postForm.title ||
              !postForm.slug_category_id ||
              !postForm.introduction ||
              postForm.status === "live",
            content: <span className="text-white">Preview</span>,
            onClick: async () => {
              let temp = _.cloneDeep(postForm);
              await createTempPostForPreview(temp, (data) => {
                window.open(
                  `${process.env.REACT_APP_MYWHEELS_WEB_URL}/temp-preview${data.slug}`,
                  "_blank"
                );
              });
            },
          },
        ]}
      />
      <CustomModal
        size={"xl"}
        isOpen={showChoosePost}
        onClose={() => setChoosePost(false)}
      >
        <FurtherReadingModal
          flattenCategories={flattenCategories}
          onSubmit={(val) => {
            setTempSelectedPost(val);
            onChangeField(
              "further_reading_post_id",
              val.map((post) => post.id)
            );
            setChoosePost(false);
          }}
          onClose={() => setChoosePost(false)}
        />
      </CustomModal>
      <CustomModal isOpen={showAddVideo} onClose={() => setShowAddVideo(false)}>
        <VideoModal
          onSubmit={(val) => {
            setPostVideo([...postVideos, val]);
            setShowAddVideo(false);
          }}
          postID={null}
        />
      </CustomModal>
      <CustomModal
        isOpen={selectedPostVideoIndex !== null}
        onClose={() => setSelectedPostVideoIndex(null)}
      >
        <VideoModal
          selectedVideo={postVideos[selectedPostVideoIndex]}
          onSubmit={(val) => {
            let temp = _.cloneDeep(postVideos);
            temp[selectedPostVideoIndex] = val;

            setPostVideo(temp);
            setSelectedPostVideoIndex(null);
          }}
          postID={null}
        />
      </CustomModal>
      <CustomModal isOpen={showAddModel} onClose={() => setAddModel(false)}>
        <CarModelModal
          postCarModels={postCarModels}
          onSubmit={(val) => {
            setAddModel(false);
            setCarModel([...postCarModels, val]);
          }}
        />
      </CustomModal>
      <CustomModal isOpen={showAddBrand} onClose={() => setAddBrand(false)}>
        <CarModelBrand
          postCarBrands={postCarBrands}
          onSubmit={(val) => {
            setAddBrand(false);
            setCarBrands([...postCarBrands, val]);
          }}
        />
      </CustomModal>
    </>
  );
}
