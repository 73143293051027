import { Component } from "react";
import { connect } from "react-redux";

import { Get, Post, Put } from "utils/axios";
import { queryHandler } from "utils/queryHandler";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      posts: [],
      videographers: [],
      reporters: [],
      postCounts: 0,
      pageIndex: 1,
      selectedDeletePost: null,
      selectedPost: null,
      showEditPostModal: false,
      showCreatePostModal: false,
      searchParams: [
        {
          label: "Title",
          value: `title`,
          type: "text",
          param: ``,
          col: 6,
        },
        {
          label: "Category",
          value: `slug_category_id`,
          type: "select",
          options: [],
          param: "",
          col: 6,
        },
        {
          label: "From Date (Published)",
          value: `published_datetype=between&published_start_at`,
          type: "date",
          param: null,
          defaultVal: null,
          col: 6,
        },
        {
          label: "To Date (Published)",
          value: `published_end_at`,
          type: "date",
          param: null,
          defaultVal: null,
          col: 6,
          minDateIndex: 2,
        },
        {
          label: "From Date (Scheduled)",
          value: `datetype=between&start_at`,
          type: "date",
          param: null,
          defaultVal: null,
          col: 6,
        },
        {
          label: "To Date (Scheduled)",
          value: `end_at`,
          type: "date",
          param: null,
          defaultVal: null,
          col: 6,
          minDateIndex: 4,
        },
      ],
    };

    onChangePostHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getVideographers = () => {
      Get(
        `/mywheels/configcategoryitems/getbykey/videographer`,
        this.getVideographersSuccess,
        this.getVideographersError,
        this.load
      );
    };
    getVideographersSuccess = (payload) => {
      this.setState({
        videographers: payload.data.rows,
      });
    };

    getVideographersError = (error) => requestError(error);

    addNewReporterOrVideographer = async (payload, type) => {
      const list =
        type === "reporter" ? this.state.reporters : this.state.videographers;
      const selectedValue = list.find(
        (eachVideographer) => eachVideographer.name === payload.name
      );
      if (selectedValue) {
        return selectedValue;
      } else {
        return await new Promise((resolve) => {
          Post(
            `/mywheels/configcategoryitems`,
            { ...payload, configCategoryId: type === "reporter" ? 2 : 1 },
            (payload) => {
              resolve(payload?.data);
              this.getVideographers();
              this.getReporters();
            },
            (error) => requestError(error),
            this.load
          );
        });
      }
    };

    getReporters = () => {
      Get(
        `/mywheels/configcategoryitems/getbykey/reporter`,
        this.getReportersSuccess,
        this.getReportersError,
        this.load
      );
    };

    getReportersSuccess = (payload) => {
      this.setState({
        reporters: payload.data.rows,
      });
    };

    getReportersError = (error) => requestError(error);

    getImageUrl = (id) => {
      return new Promise((resolve, reject) => {
        Get(
          `/get-wagtail-images/${id}`,
          (payload) => resolve(payload.data.file),
          (error) => reject(error),
          this.load
        );
      });
    };

    getPost = (val) => {
      Get(
        `/non-deleted-posts?${
          !val
            ? `type=post&platform=myWheels&offset=${
                (this.state.pageIndex - 1) * 10 > 0
                  ? (this.state.pageIndex - 1) * 10
                  : 0
              }&limit=10`
            : `type=post&platform=myWheels${val}&offset=${
                (this.state.pageIndex - 1) * 10 > 0
                  ? (this.state.pageIndex - 1) * 10
                  : 0
              }&limit=10`
        }`,
        this.getPostSuccess,
        this.getPostError,
        this.load
      );
    };

    getPostById = (id, val) => {
      Get(
        `/posts/get?postId=${id} ${
          val
            ? `&type=post&platform=myWheels&${val}`
            : "&type=post&platform=myWheels"
        }`,
        this.getPostIdSuccess,
        this.getPostIdError,
        this.load
      );
    };

    getPostIdSuccess = (payload) => {
      if (payload) {
        if (payload.data) {
          this.setState(
            {
              showEditPostModal: true,
              selectedPost: payload.data[0],
              pageIndex: payload?.pageIndex < 0 ? 1 : payload?.pageIndex,
            },
            () => {
              this.getPost();
            }
          );
        }
      }
    };

    getPostIdError = (error) => {
      requestError(error);
    };

    getPostSuccess = (payload) => {
      this.setState({
        posts: payload.data.rows,
        postCounts: payload.data.count,
      });
    };
    getPostError = (error) => requestError(error);

    getSelectedPost = (val) => {
      Get(
        `/posts/single?slug=${val}`,
        this.getSelectedPostSuccess,
        this.getSelectedPostError,
        this.load
      );
    };

    getSelectedPostSuccess = (payload) => {
      // Create a temporary div to parse the HTML
      let tempApiCall = [];
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = payload.data.content;

      // Apply the logic to modify the HTML
      const embeddedImages = tempDiv.querySelectorAll('[embedtype="image"]');

      for (let index = 0; index < embeddedImages.length; index++) {
        tempApiCall.push(this.getImageUrl(embeddedImages[index].id));
      }

      Promise.all(tempApiCall)
        .then((res) => {
          embeddedImages.forEach(async (embeddedImage, index) => {
            const imgElement = document.createElement("img");

            imgElement.src = `https://mywheels-production.s3.amazonaws.com/media/${res[index]}`;

            const attributes = embeddedImage.attributes;
            if ((attributes?.format?.value).indexOf("fullwidth") > -1) {
              imgElement.style.width = "100%";
            }
            for (let i = 0; i < attributes.length; i++) {
              const attr = attributes[i];
              if (attr.name !== "embedtype" && attr.name !== "embedsrc") {
                imgElement.setAttribute(attr.name, attr.value);
              }
            }

            if (attributes?.alt?.value) {
              const figureElement = document.createElement("figure");
              figureElement.appendChild(imgElement);
              figureElement.classList.add("image", "image_resized");

              const figcaptionElement = document.createElement("figcaption");
              figcaptionElement.innerText = attributes.alt.value;
              figureElement.appendChild(figcaptionElement);

              embeddedImage.parentNode.replaceChild(
                figureElement,
                embeddedImage
              );
            } else {
              embeddedImage.parentNode.replaceChild(imgElement, embeddedImage);
            }
          });

          this.setState({
            selectedPost: {
              ...payload.data,
              content: tempDiv.innerHTML,
              mywheels_tags: [],
            },
            showEditPostModal: true,
          });
        })
        .catch((err) => {
          requestError(err);
        });
    };
    getSelectedPostError = (error) => requestError(error);

    createTempPostForPreview = (dataToSubmit, previewPost) => {
      Post(
        `/temp-posts`,
        {
          ...dataToSubmit,
          name: JSON.stringify(dataToSubmit.name),
        },
        (res) => {
          previewPost(res?.data);
        },
        () => {},
        this.load
      );
    };

    updatePost = (dataToSubmit) => {
      Put(
        `/posts/${dataToSubmit.id}`,
        {
          ...dataToSubmit,
          name: JSON.stringify(dataToSubmit.name),
        },
        this.updatePostSuccess,
        this.updatePostError,
        this.load
      );
    };
    updatePostSuccess = () => {
      requestSuccess("Record has been updated successfully.");
      let tempQeuery = queryHandler(this.state.searchParams);

      this.setState({
        selectedDeletePost: null,
      });
      this.setState({ showEditPostModal: false });

      this.getPost(tempQeuery);
    };
    updatePostError = (error) => requestError(error);

    handleCarModel = (payload) => {
      return new Promise((resolve, reject) => {
        Post(
          `/mywheels/post/car-brand-models`,
          payload,
          (payload) => resolve(payload),
          (error) => reject(error),
          this.load
        );
      });
    };

    handleCarBrands = (payload) => {
      return new Promise((resolve, reject) => {
        Post(
          `/mywheels/post/car-brands`,
          payload,
          (payload) => resolve(payload),
          (error) => reject(error),
          this.load
        );
      });
    };

    handleUploadPostImage = (payload) => {
      return new Promise((resolve, reject) => {
        Post(
          `/post-galleries`,
          payload,
          (payload) => resolve(payload),
          (error) => reject(error),
          this.load
        );
      });
    };

    handleCreatePostVideo = (payload) => {
      return new Promise((resolve, reject) => {
        Post(
          `/videos`,
          payload,
          (payload) => resolve(payload),
          (error) => reject(error),
          this.load
        );
      });
    };

    handleCreatePostCat = (payload) => {
      return new Promise((resolve, reject) => {
        Post(
          `/mywheels/categories-posts`,
          payload,
          (payload) => resolve(payload),
          (error) => reject(error),
          this.load
        );
      });
    };

    createPost = (
      dataToSubmit,
      postVideos,
      postCategories,
      imageData,
      postCarModels,
      postCarBrands
    ) => {
      Post(
        `/posts`,
        {
          ...dataToSubmit,
          name: JSON.stringify(dataToSubmit.name),
        },
        (payload) =>
          this.createPostSuccess(
            payload,
            postVideos,
            postCategories,
            imageData,
            postCarModels,
            postCarBrands
          ),
        this.createPostError,
        this.load
      );
    };
    createPostSuccess = (
      payload,
      postVideos,
      postCategories,
      imageData,
      postCarModels,
      postCarBrands
    ) => {
      if (postCategories.length > 0) {
        let postCatApi = [];
        for (let index = 0; index < postCategories.length; index++) {
          postCatApi.push(
            this.handleCreatePostCat({
              ...postCategories[index],
              post_id: payload.data.id,
            })
          );
        }
        Promise.all(postCatApi)
          .then((responses) => {
            console.log(responses);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (postVideos.length > 0) {
        let postVideoApi = [];
        for (let index = 0; index < postVideos.length; index++) {
          postVideoApi.push(
            this.handleCreatePostVideo({
              post_id: payload.data.id,
              url: postVideos[index]["url"],
            })
          );
        }

        // video create
        Promise.all(postVideoApi)
          .then((responses) => {
            console.log(responses);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (imageData.length > 0) {
        let tempApiCall = [];

        for (let index = 0; index < imageData.length; index++) {
          tempApiCall.push(
            this.handleUploadPostImage({
              ...imageData[index],
              post_id: payload.data.id,
            })
          );
        }
      }

      if (postCarModels.length > 0) {
        let tempApiCall = [];

        for (let index = 0; index < postCarModels.length; index++) {
          tempApiCall.push(
            this.handleCarModel({
              mywheels_car_brand_model_id: postCarModels[index]?.id,
              post_id: payload.data.id,
            })
          );
        }
      }

      if (postCarBrands.length > 0) {
        let tempApiCall = [];

        for (let index = 0; index < postCarBrands.length; index++) {
          tempApiCall.push(
            this.handleCarBrands({
              mywheels_car_brand_id: postCarBrands[index]?.id,
              post_id: payload.data.id,
            })
          );
        }
      }

      requestSuccess("Record has been created successfully.");
      this.setState({ showCreatePostModal: false });
      let tempQeuery = queryHandler(this.state.searchParams);

      this.getPost(tempQeuery);
    };
    createPostError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          getVideographers={this.getVideographers}
          getReporters={this.getReporters}
          videographers={this.state.videographers}
          reporters={this.state.reporters}
          postCounts={this.state.postCounts}
          pageIndex={this.state.pageIndex}
          selectedDeletePost={this.state.selectedDeletePost}
          posts={this.state.posts}
          searchParams={this.state.searchParams}
          showCreatePostModal={this.state.showCreatePostModal}
          showEditPostModal={this.state.showEditPostModal}
          selectedPost={this.state.selectedPost}
          onLoadPost={this.state.loading}
          getPost={this.getPost}
          updatePost={this.updatePost}
          createPost={this.createPost}
          onChangePostHOC={this.onChangePostHOC}
          getSelectedPost={this.getSelectedPost}
          getPostById={this.getPostById}
          addNewReporterOrVideographer={this.addNewReporterOrVideographer}
          createTempPostForPreview={this.createTempPostForPreview}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
